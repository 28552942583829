
export default function searchNav() {
    let prevFocus;

    $('.js-navsearch:not(.js-navsearch-done)').each(function() {
        const wrapper = $(this);
        const link = wrapper.find('.js-navsearch-link');
        const close = $('.js-navsearch-close'); //Theres more than one until ST-4422 is closed
        const searchSub = $('.l-nav-sub__search-sub')

        const closeNavMenu = (e) => {
            searchSub.hide();
            link.parent().removeClass('hover');
            prevFocus.focus();

            e.preventDefault()
        }

        link.on('click', function(e) {
            if (link.parent().hasClass('hover')) {
                //close
                closeNavMenu()
            }
            else {
                //open
                prevFocus = document.activeElement;
                searchSub.css('top', `${$(this).height() + $(this).offset().top + 20}px`)
                searchSub.show();
                link.parent().addClass('hover');

                $('.js-freeform-search').focus();
            }

            e.preventDefault()
        });

        close.on('click', closeNavMenu);

        // done
        wrapper.addClass('js-navsearch-done');
    });
}
